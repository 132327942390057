html, body {
    height: 100%;
}

.wrapperx {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    /* background-color: red; */
}

.footerx {
    margin-top: auto;
    /* background-color: #f8f9fa; */
    border-top: 1px solid #dee2e6;
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    color: #212529;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #e6e6e6;
    color: black;
    padding: 20px;
    text-align: center;
    z-index: 999;
  }
  
  .wrapper {
    padding-bottom: 100px; /* adjust as necessary to account for footer height */
  }

.footer a {
    color: #212529;
    text-decoration: none;
    transition: all 0.2s ease;
}

.footer a:hover {
    color: #007bff;
}