/* @media (max-width: 768px) {
  img {
    width: 125%;
  }
  .second-level-text {
    font-size: 15px !important;
  }
} */

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* set div text color to light gray and increase size and allow the size to scale with the screen */
.banner-text {
  color: #a9a9a9;
  font-size: 7.0vw;

}

/* .footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
} */

/* .footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #e6e6e6;
  color: black;
  padding: 10px;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
} */

.main-bg { 
  background-color: #f8f9fa;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #212529;
}

.footer a {
  color: #212529;
  text-decoration: none;
  transition: all 0.2s ease;
  margin: 0 10px;
}

.footer a:hover {
  color: #007bff;
}

.services-desc { 
  text-align: left;
}

/* class foo inherits from banner-text */
.banner-text > .secondary {
  font-size: 3.5vw;
  font-style: italic;
}

/* .banner-img {
  width: 50%;
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
} */

/* class that sets an image's width and height to start at 50%, then scale according to the screen size */
.banner-image { 
  width: 90%;
  height: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.second-level-text {
  color: #a9a9a9;
  font-style: italic;
  font-size: 3.5vw;
}

/* let's scale down the <img> with id of "testImg" if the phone is small */
@media (max-width: 768px) { 
   #testImg { 
    width: 120% !important;
  }
  .second-level-text {
    font-size: 5.0vw;
  }
}

.contact-send-btn {
  color: #fff;
  border-radius: 0;
  margin-top: 20px;
}

.contact-form .contact-form-group {
  margin-bottom: 10px;
  padding: 0px;
  width: 100%;
}

.contact-input-msg {
  height: 100px;
} 

#contact-modal .modal-dialog {
  margin-top: 10%;
}


#testDiv .fade-in {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

#testDiv .fade-in.active {
  opacity: 1;
}
